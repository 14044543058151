import { zodResolver } from "@hookform/resolvers/zod";
import { createFileRoute, notFound } from "@tanstack/react-router";
import { useForm } from "react-hook-form";
import { z } from "zod";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@customerPortal/components/ui/form";
import { useMemo, useState } from "react";
import { Input } from "@customerPortal/components/ui/input";
import { Textarea } from "@customerPortal/components/ui/textarea";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@customerPortal/components/ui/select";
import { Button } from "@customerPortal/components/Button";
import { toast } from "sonner";

export const Route = createFileRoute(
  "/business/$businessSlug/_card/_orders/orders/update",
)({
  component: UpdateOrder,
  validateSearch: z.object({
    trackingId: z.string().optional(),
  }),
  loader: ({ context: { orderConfig } }) => {
    if (!orderConfig.enableUpdates || orderConfig.updateOptions.length === 0) {
      throw notFound();
    }
  },
  notFoundComponent: () => (
    <p className="text-center">Order updates are disabled</p>
  ),
});

const formSchema = z.object({
  trackingId: z.string(),
  email: z.string().email(),
  changeType: z.string(),
  changeDescription: z.string(),
});

function UpdateOrder() {
  const { businessSlug } = Route.useParams();
  const { trackingId } = Route.useSearch();
  const {
    trpc,
    orderConfig: { trackingIdPlaceholder, updateOptions, updateExtraInfo },
    customerPortalConfig: { primaryColor, backupEmail, shopDomain },
  } = Route.useRouteContext();

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      trackingId,
      changeType:
        updateOptions.length === 1 ? updateOptions[0].slug : undefined,
    },
  });

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [confirmationText, setConfirmationText] = useState<string | undefined>(
    undefined,
  );

  function onSubmit(values: z.infer<typeof formSchema>) {
    setIsSubmitting(true);
    trpc.customerPortal.orders.submitUpdate
      .mutate({
        businessSlug,
        ...values,
      })
      .then(({ customerNote }) => {
        setConfirmationText(customerNote);
        // TODO: Need to also set query params
      })
      .catch((err) => {
        console.error("Failed to submit order update", err);
        toast(
          `Failed to submit order update request. Please try again later or contact ${backupEmail}.`,
        );
      })
      .finally(() => {
        setIsSubmitting(false);
      });
  }

  const selectedChangeType = form.watch("changeType");

  // TODO: Use better naming
  const selectedUpdateOption = useMemo(
    () => updateOptions.find((option) => option.slug === selectedChangeType),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [selectedChangeType],
  );

  if (confirmationText) {
    // TODO: This will not display correct message for multiple options upon refresh
    return (
      <div>
        <p className="mt-1 text-center text-sm">{confirmationText}</p>
        <a href={`https://${shopDomain}`} target="_blank" rel="noreferrer">
          <Button className="mt-6 w-full" backgroundColor={primaryColor}>
            Back to {shopDomain}
          </Button>
        </a>
      </div>
    );
  }

  return (
    <>
      <div className="flex w-full pb-2">
        <span className="mx-auto text-base font-medium text-gray-500">
          Request an order update
        </span>
      </div>
      <Form {...form}>
        <form
          onSubmit={(e) => void form.handleSubmit(onSubmit)(e)}
          className="space-y-4"
        >
          <FormField
            control={form.control}
            name="trackingId"
            disabled={isSubmitting}
            render={({ field }) => (
              <FormItem>
                <FormLabel className="text-gray-500">Order ID</FormLabel>
                <FormControl>
                  <Input
                    placeholder={trackingIdPlaceholder ?? "FRXXXXX"}
                    className="text-base text-black"
                    {...field}
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="email"
            disabled={isSubmitting}
            render={({ field }) => (
              <FormItem>
                <FormLabel className="text-gray-500">Email</FormLabel>
                <FormControl>
                  <Input
                    type="email"
                    placeholder="name@example.com"
                    className="text-base text-black"
                    {...field}
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="changeType"
            disabled={isSubmitting}
            render={({ field }) => (
              <FormItem>
                <FormLabel className="text-gray-500">
                  What would you like changed?
                </FormLabel>
                <Select
                  onValueChange={field.onChange}
                  defaultValue={field.value}
                >
                  <FormControl>
                    <SelectTrigger>
                      <SelectValue placeholder="Requested change" />
                    </SelectTrigger>
                  </FormControl>
                  <SelectContent>
                    {updateOptions.map((option) => (
                      <SelectItem key={option.slug} value={option.slug}>
                        {option.readableName}
                      </SelectItem>
                    ))}
                  </SelectContent>
                </Select>
                <FormMessage />
              </FormItem>
            )}
          />
          {selectedChangeType && (
            <FormField
              control={form.control}
              name="changeDescription"
              disabled={isSubmitting}
              render={({ field }) => (
                <FormItem>
                  <FormLabel className="text-gray-500">Description</FormLabel>
                  <FormControl>
                    {/* TODO: Make textarea resize automatically */}
                    <Textarea
                      placeholder={
                        selectedUpdateOption?.placeholder ??
                        "Describe your change"
                      }
                      className="text-base text-black"
                      {...field}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
          )}
          {updateExtraInfo && (
            <p className="text-xs font-light">{updateExtraInfo}</p>
          )}
          <Button
            className="mt-8 w-full"
            type="submit"
            backgroundColor={primaryColor}
            disabled={isSubmitting}
          >
            Submit
          </Button>
        </form>
      </Form>
    </>
  );
}
