import { createFileRoute } from "@tanstack/react-router";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@customerPortal/components/ui/form";
import { Input } from "@customerPortal/components/ui/input";
import { z } from "zod";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { Button } from "@customerPortal/components/Button";

export const Route = createFileRoute(
  "/business/$businessSlug/_card/subscriptions/",
)({
  component: SubscriptionInput,
  validateSearch: z.object({
    email: z.string().optional(),
  }),
});

const formSchema = z.object({
  email: z.string().email(),
});

function SubscriptionInput() {
  const { email } = Route.useSearch();
  const navigate = Route.useNavigate();
  const {
    customerPortalConfig: { primaryColor },
  } = Route.useRouteContext();

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      email: email ?? "",
    },
  });

  function onSubmit(values: z.infer<typeof formSchema>) {
    void navigate({
      from: "/business/$businessSlug/subscriptions",
      to: "/business/$businessSlug/subscriptions/manage",
      search: { email: values.email },
    });
  }

  return (
    <>
      <div className="flex flex-col">
        <span className="mx-auto text-base font-medium text-gray-500">
          Access your subscription
        </span>
      </div>
      <Form {...form}>
        <form
          onSubmit={(e) => void form.handleSubmit(onSubmit)(e)}
          className="mx-auto mt-0 flex w-full max-w-[350px] flex-col items-center justify-center"
        >
          <FormField
            control={form.control}
            name="email"
            render={({ field }) => (
              <div className="w-full space-y-0">
                <FormItem className="w-full">
                  <FormLabel className="text-gray-500">Email</FormLabel>
                  <FormControl>
                    <Input
                      required
                      autoFocus
                      placeholder="john@example.com"
                      type="email"
                      className="text-base text-black"
                      {...field}
                    />
                  </FormControl>
                  <FormMessage className="max-w-[90%] truncate" />
                </FormItem>
              </div>
            )}
          />
          <Button
            type="submit"
            className="mt-8 w-full"
            backgroundColor={primaryColor}
          >
            Find Subscription
          </Button>
        </form>
      </Form>
    </>
  );
}
