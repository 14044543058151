import { createTRPCProxyClient, createWSClient, wsLink } from "@trpc/client";
import { env } from "./env";
import { RouterProvider, createRouter } from "@tanstack/react-router";
import { routeTree } from "./routeTree.gen";
import type { AppRouter } from "@be/exports";

const vanillaTrpcClient = createTRPCProxyClient<AppRouter>({
  links: [
    wsLink({
      client: createWSClient({
        url: env.CUSTOMER_PORTAL_TRPC_WS_URL,
      }),
    }),
  ],
});

const router = createRouter({
  routeTree,
  defaultNotFoundComponent: () => <p>Not found</p>,
  context: {
    trpc: vanillaTrpcClient,
  },
});

declare module "@tanstack/react-router" {
  interface Register {
    router: typeof router;
  }
}

export function App() {
  return <RouterProvider router={router} />;
}
