import { z } from "zod";

const injectedEnv = process.env;

export const env = z
  .object({
    NODE_ENV: z
      .union([
        z.literal("production"),
        z.literal("development"),
        z.literal("test"),
      ])
      .default("development"),
    CUSTOMER_PORTAL_BASE_API_URL: z.string().url(),
    CUSTOMER_PORTAL_TRPC_WS_URL: z.string().url(),
    CUSTOMER_PORTAL_SENTRY_DSN: z
      .string()
      .default(
        "https://05e3ae61bfa06b17f1727e5e16157e94@o4506240732889088.ingest.sentry.io/4506478482751488",
      ),
  })
  .parse(injectedEnv);
