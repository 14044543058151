import { Outlet, createFileRoute, notFound } from "@tanstack/react-router";
import { Button } from "@customerPortal//components/ui/button";
import { Disclosure } from "@headlessui/react";
import {
  Card,
  CardContent as DefaultCardContent,
} from "@customerPortal/components/ui/card";
import { Cross1Icon, HamburgerMenuIcon } from "@radix-ui/react-icons";

export const Route = createFileRoute("/business/$businessSlug/_card")({
  component: LayoutComponent,
  beforeLoad: async ({ params: { businessSlug }, context: { trpc } }) => {
    const customerPortalConfig = await trpc.customerPortal.getConfig.query({
      businessSlug,
    });
    if (!customerPortalConfig) {
      throw notFound();
    }
    return { customerPortalConfig };
  },
});

function LayoutComponent() {
  const {
    customerPortalConfig: {
      logoUrl,
      enableOctocomBranding,
      shopDomain,
      navbarLinks,
    },
  } = Route.useRouteContext();

  return (
    <>
      <div className="">
        <Disclosure as="nav" className="bg-white sm:shadow">
          {({ open }) => (
            <>
              <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
                <div className="flex h-16 justify-between">
                  <div className="flex">
                    <div className="flex flex-shrink-0 items-center">
                      <a href={`https://${shopDomain}`}>
                        {logoUrl && (
                          <img
                            className="h-10 w-auto"
                            src={logoUrl}
                            alt="Company logo"
                          />
                        )}
                      </a>
                    </div>
                    {navbarLinks && (
                      <div className="hidden items-center sm:ml-20 sm:flex sm:space-x-8">
                        {navbarLinks.map(({ label, href }) => (
                          <a key={label} href={href}>
                            <Button
                              variant="link"
                              size="lg"
                              className="p-0 text-black"
                            >
                              {label}
                            </Button>
                          </a>
                        ))}
                      </div>
                    )}
                  </div>
                  {/* Mobile menu button */}
                  <div className="-mr-2 flex items-center sm:hidden">
                    <Disclosure.Button className="relative inline-flex items-center justify-center rounded-md p-2 text-gray-400 !outline-none transition-none focus:bg-transparent focus:outline-none focus:ring-0">
                      <span className="absolute -inset-0.5" />
                      <span className="sr-only">Open main menu</span>
                      {open ? (
                        <Cross1Icon className="h-6 w-6" />
                      ) : (
                        <HamburgerMenuIcon className="h-6 w-6" />
                      )}
                    </Disclosure.Button>
                  </div>
                </div>
              </div>

              {/* mobile */}
              <Disclosure.Panel className="absolute z-50 w-full border-b bg-white sm:hidden">
                <div className="flex flex-col items-start space-y-1 px-4 pb-3 pt-2">
                  <Button variant="link" size="lg" className="p-0 text-black">
                    Food Hampers
                  </Button>
                  <Button variant="link" size="lg" className="p-0 text-black">
                    Best Sellers
                  </Button>
                  <Button variant="link" size="lg" className="p-0 text-black">
                    FAQs
                  </Button>
                  <Button variant="link" size="lg" className="p-0 text-black">
                    About Us
                  </Button>
                </div>
              </Disclosure.Panel>
            </>
          )}
        </Disclosure>
        <div className="overflow-none minh-[100dvh] relative flex w-full flex-col items-center pb-5 sm:mt-8 lg:w-auto lg:justify-center lg:py-5 lg:pb-0">
          {/* <div className="w-full pb-5 lg:w-auto lg:pb-0"> */}
          {/* mobile view  */}
          <div className="flex w-full flex-col px-7 lg:hidden">
            <div className="mt-5 w-full">
              <Outlet />
            </div>
          </div>

          {/* desktop view */}
          <Card className="hidden h-full min-w-[24rem] max-w-sm rounded-none shadow-none lg:block lg:h-auto  lg:rounded-xl lg:shadow">
            <DefaultCardContent className="mt-0 px-7 py-6">
              <Outlet />
            </DefaultCardContent>
          </Card>

          {enableOctocomBranding && (
            <span className="text-gray mt-5 block text-center text-sm">
              <a target="_blank" rel="noreferrer" href="https://www.octocom.ai">
                Powered by Octocom
              </a>
            </span>
          )}
          {/* </div> */}
        </div>
      </div>
    </>
  );
}
