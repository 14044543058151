import { createFileRoute, redirect } from "@tanstack/react-router";
import { z } from "zod";
import { format } from "date-fns";
import clsxm from "@customerPortal/utils/clsxm";
import {
  Tabs,
  TabsContent,
  TabsList,
  TabsTrigger,
} from "@customerPortal/components/ui/tabs";
import { Button } from "@customerPortal/components/Button";
import { useState } from "react";
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectLabel,
  SelectTrigger,
  SelectValue,
} from "@customerPortal/components/ui/select";
import { toast } from "sonner";
import type { RouterOutput } from "@be/exports";

export const Route = createFileRoute(
  "/business/$businessSlug/_card/_orders/orders/manage",
)({
  component: ManageOrders,
  validateSearch: z.object({
    email: z.string().optional(),
    trackingId: z.string().optional(),
  }),
  loaderDeps: ({ search: { email, trackingId } }) => ({ email, trackingId }),
  loader: async ({
    params: { businessSlug },
    deps: { email, trackingId },
    context: { trpc },
  }) => {
    const orders = await trpc.customerPortal.orders.track.query({
      businessSlug,
      email,
      trackingId,
    });

    if (orders.length === 0) {
      toast("No orders found");
      throw redirect({
        from: "/business/$businessSlug/orders/manage",
        to: "/business/$businessSlug/orders/find",
        search: { email, trackingId },
      });
    }

    return { orders };
  },
});

type Order = RouterOutput["customerPortal"]["orders"]["track"][0];

export function ManageOrders() {
  const navigate = Route.useNavigate();
  const { orders } = Route.useLoaderData();
  const {
    customerPortalConfig: { primaryColor },
  } = Route.useRouteContext();
  const { email, trackingId } = Route.useSearch();

  const [selectedOrder, setSelectedOrder] = useState<Order | null>(
    orders.length === 1 ? orders[0] : null,
  );

  return (
    <div className="flex flex-col">
      <span className="mx-auto text-base font-medium text-gray-500">
        {orders.length === 1 ? <>Order details</> : <>Orders for {email}</>}
      </span>
      {orders.length > 1 && (
        <>
          <span className="items-center text-sm">
            Orders for <span className="text-gray-500">{email}</span>
          </span>
          <Select
            onValueChange={(externalOrderId) => {
              const order = orders.find(
                (o) => o.externalId === externalOrderId,
              );

              if (order) {
                setSelectedOrder(order);
              }
            }}
          >
            <SelectTrigger className="mt-3 w-full">
              <SelectValue placeholder="Select your order" />
            </SelectTrigger>
            <SelectContent>
              <SelectGroup>
                <SelectLabel>Select your order</SelectLabel>
                {orders.map((o) => (
                  <SelectItem key={o.externalId} value={o.externalId}>
                    {o.trackingId}
                  </SelectItem>
                ))}
              </SelectGroup>
            </SelectContent>
          </Select>
        </>
      )}

      {!selectedOrder && (
        <Button
          onClick={() =>
            void navigate({
              from: "/business/$businessSlug/orders/manage",
              to: "/business/$businessSlug/orders/find",
              search: { email, trackingId },
            })
          }
          variant="outline"
          className="mt-4 text-black"
          size="sm"
        >
          Go back
        </Button>
      )}

      {selectedOrder && (
        <Tabs
          defaultValue="0"
          className="mt-4 flex w-full flex-col items-center"
        >
          {selectedOrder.fulfillments.length > 1 && (
            <div className="mx-auto flex w-full flex-row justify-items-center overflow-x-auto">
              <div className="mx-auto">
                <TabsList>
                  {selectedOrder.fulfillments.map((_, idx) => (
                    <TabsTrigger key={idx} value={idx.toString()}>
                      Shipment {idx + 1}
                    </TabsTrigger>
                  ))}
                </TabsList>
              </div>
            </div>
          )}
          {selectedOrder.fulfillments.map((fulfillment, idx) => (
            <TabsContent key={idx} className="w-full" value={idx.toString()}>
              {/* rows */}
              <div>
                <div className="flex flex-col">
                  <Row label="Tracking ID:" value={selectedOrder.trackingId} />
                  {selectedOrder.orderDate && (
                    <>
                      <RowDivider />
                      <Row
                        label="Order Date:"
                        value={format(new Date(selectedOrder.orderDate), "Pp")}
                      />
                    </>
                  )}
                  {selectedOrder.estimatedDeliveryDate && (
                    <>
                      <RowDivider />
                      <Row
                        label="Estimated Delivery Date:"
                        value={format(
                          new Date(selectedOrder.estimatedDeliveryDate),
                          "P",
                        )}
                      />
                    </>
                  )}
                  {fulfillment.courier && (
                    <>
                      <RowDivider />
                      <Row
                        label="Carrier:"
                        value={fulfillment.courier.toUpperCase()}
                      />
                    </>
                  )}
                  {fulfillment.trackingNumber && (
                    <>
                      <RowDivider />
                      <Row
                        label="Tracking Number:"
                        value={fulfillment.trackingNumber}
                      />
                    </>
                  )}
                  {fulfillment.items?.map((item, idx) => (
                    <>
                      <RowDivider />
                      <Row
                        key={idx}
                        label={
                          fulfillment.items?.length === 1
                            ? "Item:"
                            : `Item ${idx + 1}:`
                        }
                        value={item.name}
                      />
                    </>
                  ))}
                </div>
              </div>

              {/* buttons */}
              <div className="mt-4 flex w-full flex-col items-center justify-center">
                {fulfillment.trackingUrl && (
                  <a
                    className="w-full text-sm"
                    rel="noreferrer"
                    href={fulfillment.trackingUrl}
                    target="_blank"
                  >
                    <Button
                      className="w-full"
                      size="default"
                      backgroundColor={primaryColor}
                    >
                      Open Tracking Link
                    </Button>
                  </a>
                )}

                {selectedOrder.invoiceUrls && (
                  <div className="flex w-full flex-col items-center justify-center">
                    {selectedOrder.invoiceUrls.map((url, index) => (
                      <a
                        key={index}
                        className="w-full text-sm"
                        href={url}
                        target="_blank"
                        rel="noreferrer"
                      >
                        <Button
                          className="mt-3 w-full"
                          variant="outline"
                          size="default"
                        >
                          Download invoice{" "}
                          {(selectedOrder.invoiceUrls?.length ?? 0) > 1
                            ? `#${index + 1}`
                            : ""}
                        </Button>
                      </a>
                    ))}
                  </div>
                )}

                <Button
                  onClick={() =>
                    void navigate({
                      from: "/business/$businessSlug/orders/manage",
                      to: "/business/$businessSlug/orders/find",
                      search: { email, trackingId },
                    })
                  }
                  variant="link"
                  className="mt-1 text-xs text-black"
                  size="sm"
                >
                  Go back
                </Button>
              </div>
            </TabsContent>
          ))}
          {selectedOrder.fulfillments.length === 0 && (
            <TabsContent className="w-full" value="0">
              <div className="flex flex-col">
                <Row label="Tracking ID:" value={selectedOrder.trackingId} />
                <RowDivider />
                {selectedOrder.orderDate && (
                  <>
                    <Row
                      label="Order Date:"
                      value={format(new Date(selectedOrder.orderDate), "Pp")}
                    />
                    <RowDivider />
                  </>
                )}
                <Row label="Status:" value="Not shipped" />
              </div>

              {/* buttons */}
              <div className="mt-4 flex w-full flex-col items-center justify-center">
                <Button
                  onClick={() =>
                    void navigate({
                      from: "/business/$businessSlug/orders/manage",
                      to: "/business/$businessSlug/orders/find",
                      search: { email, trackingId },
                    })
                  }
                  variant="default"
                  backgroundColor={primaryColor}
                >
                  Track Another Order
                </Button>
              </div>
            </TabsContent>
          )}
        </Tabs>
      )}
    </div>
  );
}

// TODO: This should be moved to common
function Row({
  label,
  value,
  highlight,
}: {
  label: string;
  value: string;
  highlight?: boolean;
}) {
  return (
    <div className="flex flex-row py-1.5">
      <div className="text-sm text-black">{label}</div>
      <div
        className={clsxm("ml-auto text-sm text-black", {
          "text-green-700": highlight,
        })}
      >
        {value}
      </div>
    </div>
  );
}

function RowDivider() {
  return <div className="bg-gray h-[1px] w-full"></div>;
}
