import { Outlet, createRootRouteWithContext } from "@tanstack/react-router";
import { createTRPCProxyClient } from "@trpc/client";
import type { AppRouter } from "@be/exports";
import { Toaster } from "@customerPortal/components/ui/sonner";
import React, { Suspense } from "react";

interface RouterContext {
  trpc: ReturnType<typeof createTRPCProxyClient<AppRouter>>;
}

const TanStackRouterDevtools =
  process.env.NODE_ENV === "production"
    ? () => null
    : React.lazy(() =>
        import("@tanstack/router-devtools").then((res) => ({
          default: res.TanStackRouterDevtools,
        })),
      );

export const Route = createRootRouteWithContext<RouterContext>()({
  component: () => {
    return (
      <>
        <Outlet />
        <Toaster position="top-center" visibleToasts={1} />
        <Suspense>
          <TanStackRouterDevtools />
        </Suspense>
      </>
    );
  },
});
