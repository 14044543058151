import {
  Button as OriginalButton,
  ButtonProps as OriginalButtonProps,
} from "@customerPortal/components/ui/button";
import { useState } from "react";
import chroma from "chroma-js";

interface ButtonProps extends OriginalButtonProps {
  backgroundColor?: string;
}

export function Button({ backgroundColor, ...props }: ButtonProps) {
  const [isHovered, setIsHovered] = useState(false);

  const style = {
    backgroundColor: backgroundColor
      ? isHovered
        ? chroma(backgroundColor).alpha(0.8).hex()
        : backgroundColor
      : undefined,
    ...props.style,
  };

  return (
    <OriginalButton
      {...props}
      style={style}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      {props.children}
    </OriginalButton>
  );
}
