import { zodResolver } from "@hookform/resolvers/zod";
import { createFileRoute } from "@tanstack/react-router";
import { useForm } from "react-hook-form";
import { z } from "zod";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@customerPortal/components/ui/form";
import { Input } from "@customerPortal/components/ui/input";
import { Textarea } from "@customerPortal/components/ui/textarea";
import { Button } from "@customerPortal/components/Button";
import { useState } from "react";
import { toast } from "sonner";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@customerPortal/components/ui/select";

export const Route = createFileRoute("/business/$businessSlug/_card/contact")({
  loader: async ({ context: { trpc }, params: { businessSlug } }) => {
    return await trpc.customerPortal.contact.getConfig.query({
      businessSlug,
    });
  },
  component: Contact,
});

const formSchema = z.object({
  fullName: z.string(),
  email: z.string().email(),
  reason: z.string().optional(),
  message: z.string(),
});

function Contact() {
  const {
    customerPortalConfig: { primaryColor },
    trpc,
  } = Route.useRouteContext();
  const { businessSlug } = Route.useParams();
  const { reasons } = Route.useLoaderData();

  const [isSubmitting, setIsSubmitting] = useState(false);

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      fullName: "",
      email: "",
      message: "",
    },
  });

  function onSubmit(values: z.infer<typeof formSchema>) {
    setIsSubmitting(true);
    void trpc.contactForm.submitInquiry
      .mutate({
        businessSlug,
        body: {
          email: values.email,
          inquiry: values.message,
          name: values.fullName,
        },
      })
      .then(() => {
        toast("Your message has been sent");
        form.reset();
      })
      .catch(() => {
        toast("An error occurred while sending your message");
      })
      .finally(() => {
        setIsSubmitting(false);
      });
  }

  return (
    <>
      <div className="flex w-full pb-2">
        <span className="mx-auto text-base font-medium text-gray-500">
          Contact us
        </span>
      </div>
      <Form {...form}>
        <form
          onSubmit={(e) => void form.handleSubmit(onSubmit)(e)}
          className="space-y-4"
        >
          <FormField
            control={form.control}
            name="fullName"
            render={({ field }) => (
              <FormItem>
                <FormLabel className="text-gray-500">Full name</FormLabel>
                <FormControl>
                  <Input className="text-base text-black" {...field} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="email"
            render={({ field }) => (
              <FormItem>
                <FormLabel className="text-gray-500">Email</FormLabel>
                <FormControl>
                  <Input
                    type="email"
                    placeholder="name@example.com"
                    className="text-base text-black"
                    {...field}
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          {reasons.length > 0 && (
            <FormField
              control={form.control}
              name="reason"
              render={({ field }) => (
                <FormItem>
                  <FormLabel className="text-gray-500">Reason</FormLabel>
                  <Select
                    onValueChange={field.onChange}
                    defaultValue={field.value}
                  >
                    <FormControl>
                      <SelectTrigger>
                        <SelectValue placeholder="Why are you contacting us?" />
                      </SelectTrigger>
                    </FormControl>
                    <SelectContent>
                      {reasons.map((reason) => (
                        <SelectItem key={reason.slug} value={reason.slug}>
                          {reason.title}
                        </SelectItem>
                      ))}
                    </SelectContent>
                  </Select>
                  <FormMessage />
                </FormItem>
              )}
            />
          )}
          <FormField
            control={form.control}
            name="message"
            render={({ field }) => (
              <FormItem>
                <FormLabel className="text-gray-500">Message</FormLabel>
                <FormControl>
                  {/* TODO: Make textarea resize automatically */}
                  <Textarea
                    placeholder="Write your message"
                    className="text-base text-black"
                    {...field}
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <Button
            disabled={isSubmitting}
            className="!mt-8 w-full"
            type="submit"
            backgroundColor={primaryColor}
          >
            Submit
          </Button>
        </form>
      </Form>
    </>
  );
}
