import { createFileRoute, redirect, useRouter } from "@tanstack/react-router";
import {
  AlertDialogAction,
  AlertDialogCancel,
} from "@customerPortal/components/AlertDialog";
import { Button } from "@customerPortal/components/Button";
import { LoadingCardContent } from "@customerPortal/components/LoadingCard";
import {
  AlertDialog,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger,
} from "@customerPortal/components/ui/alert-dialog";
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectLabel,
  SelectTrigger,
  SelectValue,
} from "@customerPortal/components/ui/select";
import clsxm from "@customerPortal/utils/clsxm";
import { Link } from "@tanstack/react-router";
import { addMonths, format } from "date-fns";
import { toast } from "sonner";
import { z } from "zod";
import type { RouterOutput } from "@be/exports";
import { useForm } from "react-hook-form";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@customerPortal/components/ui/form";
import { useEffect, useState } from "react";
import { zodResolver } from "@hookform/resolvers/zod";

export const Route = createFileRoute(
  "/business/$businessSlug/_card/subscriptions/manage",
)({
  component: SubscriptionManagement,
  validateSearch: z.object({
    email: z.string(),
  }),
  loaderDeps: ({ search: { email } }) => ({ email }),
  loader: async ({
    params: { businessSlug },
    deps: { email },
    context: { trpc },
  }) => {
    const subscriptions = await trpc.customerPortal.subscriptions.getAll.query({
      email,
      businessSlug,
    });

    if (subscriptions.length === 0) {
      toast("No subscriptions found for this email");
      throw redirect({
        from: "/business/$businessSlug/subscriptions/manage",
        to: "../",
        search: { email },
      });
    }

    return { subscriptions };
  },
});

type Subscription =
  RouterOutput["customerPortal"]["subscriptions"]["getAll"][0];

// ! This page is only tailored for FAM brands as they are the only
// ! ones using the subscription management feature. This needs to
// ! be generalized if we want to use it for other businesses.
function SubscriptionManagement() {
  const { subscriptions } = Route.useLoaderData();
  const {
    customerPortalConfig: { primaryColor },
  } = Route.useRouteContext();
  const { email } = Route.useSearch();
  const { trpc } = Route.useRouteContext();
  const { businessSlug } = Route.useParams();
  const router = useRouter();

  const [selectedSubscription, setSelectedSubscription] =
    useState<Subscription | null>(
      subscriptions.length === 1 ? subscriptions[0] : null,
    );

  useEffect(() => {
    // Let's update the selected subscription if the subscriptions change
    if (selectedSubscription) {
      setSelectedSubscription(
        subscriptions.find(
          (s) => s.externalId === selectedSubscription.externalId,
        ) ?? null,
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [subscriptions]);

  const [farewellText, setFarewellText] = useState<string | null>(null);

  const [isSkippingNextMonth, setIsSkippingNextMonth] = useState(false);

  if (isSkippingNextMonth) {
    return <LoadingCardContent />;
  }

  const onSkipNextMonth = () => {
    if (selectedSubscription) {
      setIsSkippingNextMonth(true);
      trpc.customerPortal.subscriptions.skipNextMonth
        .mutate({
          businessSlug,
          externalSubscriptionId: selectedSubscription.externalId,
        })
        .then(() => {
          void router.invalidate();
          setFarewellText(
            `Next order for #${selectedSubscription.externalId} skipped`,
          );
        })
        .catch(() => {
          toast("Something went wrong");
        })
        .finally(() => {
          setIsSkippingNextMonth(false);
        });
    }
  };

  if (farewellText) {
    return (
      <div className="flex flex-col items-center text-center">
        <span className="items-center text-base">{farewellText}</span>

        <Button type="submit" className="mt-6 w-full">
          <Link
            from="/business/$businessSlug/subscriptions/manage"
            to="../"
            search={{ email }}
          >
            Manage Another Subscription
          </Link>
        </Button>
      </div>
    );
  }

  return (
    <div className="flex flex-col">
      <span className="mx-auto text-base font-medium text-gray-500">
        {subscriptions.length === 1 ? (
          <>Manage your subscription</>
        ) : (
          <>
            Subscriptions for <span className="text-gray-500">{email}</span>
          </>
        )}
      </span>
      {subscriptions.length > 1 && (
        <>
          {/* <span className="items-center text-sm">
            Subscriptions for <span className="text-gray-500">{email}</span>
          </span> */}
          <Select
            onValueChange={(externalSubscriptionId) => {
              const subscription = subscriptions.find(
                (s) => s.externalId === externalSubscriptionId,
              );
              if (subscription) {
                setSelectedSubscription(subscription);
              }
            }}
          >
            <SelectTrigger className="mt-3 w-full">
              <SelectValue placeholder="Select your subscription" />
            </SelectTrigger>
            <SelectContent>
              <SelectGroup>
                <SelectLabel>Select your subscription</SelectLabel>
                {subscriptions.map((s) => (
                  <SelectItem key={s.externalId} value={s.externalId}>
                    {s.title} (#{s.externalId})
                  </SelectItem>
                ))}
              </SelectGroup>
            </SelectContent>
          </Select>
        </>
      )}
      {selectedSubscription && (
        <div className="mt-4">
          <div className="flex flex-col">
            <Row
              label="Subscription ID:"
              value={`#${selectedSubscription.externalId}`}
            />
            <RowDivider />
            <Row label="Title:" value={selectedSubscription.title} />
            <RowDivider />
            <Row
              label="Price:"
              value={`${selectedSubscription.price} ${selectedSubscription.currency}`}
            />
            {selectedSubscription.nextChargeDate && (
              <>
                <RowDivider />
                <Row
                  label="Next charge date:"
                  value={format(
                    new Date(selectedSubscription.nextChargeDate),
                    "P",
                  )}
                />
              </>
            )}
          </div>
        </div>
      )}
      <div className="mt-6 flex flex-row space-x-2">
        <AlertDialog>
          <AlertDialogTrigger
            disabled={!selectedSubscription}
            className="w-full"
          >
            <Button
              type="submit"
              className="w-full"
              disabled={!selectedSubscription}
              variant="outline"
            >
              Skip a month
            </Button>
          </AlertDialogTrigger>
          <AlertDialogContent>
            <AlertDialogHeader>
              <AlertDialogTitle>Confirm order skipping</AlertDialogTitle>
              {selectedSubscription && (
                <AlertDialogDescription>
                  Your next order will be skipped. The next payment will be at{" "}
                  {format(
                    addMonths(
                      selectedSubscription.nextChargeDate
                        ? new Date(selectedSubscription.nextChargeDate)
                        : new Date(),
                      1,
                    ),
                    "P",
                  )}
                  .
                </AlertDialogDescription>
              )}
            </AlertDialogHeader>
            <AlertDialogFooter>
              <AlertDialogCancel>Cancel</AlertDialogCancel>
              <AlertDialogAction
                onClick={onSkipNextMonth}
                backgroundColor={primaryColor}
              >
                Confirm
              </AlertDialogAction>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialog>
        <AlertDialog>
          <AlertDialogTrigger
            disabled={!selectedSubscription}
            className="w-full"
          >
            <Button
              type="submit"
              className="w-full"
              disabled={!selectedSubscription}
              backgroundColor={primaryColor}
            >
              Update sizing
            </Button>
          </AlertDialogTrigger>
          <AlertDialogContent>
            {selectedSubscription && (
              <UpdateSizingModalContent
                externalSubscriptionId={selectedSubscription.externalId}
                properties={selectedSubscription.properties}
              />
            )}
          </AlertDialogContent>
        </AlertDialog>
      </div>
    </div>
  );
}

type SizingPropertyName =
  | "leggings"
  | "tops"
  | "sports-bra"
  | "dress"
  | "sports-jacket";

const sizes: [string, ...string[]] = ["XS", "S", "M", "L", "XL", "XXL"];

const sizingFormSchema = z.object({
  leggings: z.enum(sizes).optional(),
  tops: z.enum(sizes).optional(),
  "sports-bra": z.enum(sizes).optional(),
  dress: z.enum(sizes).optional(),
  "sports-jacket": z.enum(sizes).optional(),
});

function UpdateSizingModalContent({
  externalSubscriptionId,
  properties,
}: {
  externalSubscriptionId: string;
  properties: {
    name: string;
    value: string | null;
  }[];
}) {
  const {
    trpc,
    customerPortalConfig: { primaryColor },
  } = Route.useRouteContext();

  const { businessSlug } = Route.useParams();
  const router = useRouter();

  const form = useForm<z.infer<typeof sizingFormSchema>>({
    resolver: zodResolver(sizingFormSchema),
    defaultValues: properties.reduce<Record<string, string>>(
      (acc, { name, value }) => {
        if (value !== null) {
          acc[name] = value;
        }
        return acc;
      },
      {},
    ),
  });

  function onSubmit(values: z.infer<typeof sizingFormSchema>) {
    trpc.customerPortal.subscriptions.updateSizing
      .mutate({
        externalSubscriptionId,
        businessSlug,
        sizing: values,
      })
      .then(() => {
        toast("Sizing updated");
        void router.invalidate();
      })
      .catch(() => {
        toast("Something went wrong. Could not update sizing.");
      });
  }

  console.log(properties);

  return (
    <>
      <AlertDialogHeader>
        <AlertDialogTitle>Update Subscription Sizing</AlertDialogTitle>
      </AlertDialogHeader>
      <Form {...form}>
        <form
          onSubmit={(e) => void form.handleSubmit(onSubmit)(e)}
          className="space-y-4"
        >
          <div className="space-y-4 pb-2">
            {properties.filter(isSizingProperty).map(({ name }) => (
              <FormField
                key={name}
                control={form.control}
                name={name}
                render={({ field }) => (
                  <FormItem>
                    <FormLabel className="text-gray-500">
                      {readableSizingPropertyName(name)}
                    </FormLabel>
                    <Select
                      onValueChange={field.onChange}
                      defaultValue={field.value}
                    >
                      <FormControl>
                        <SelectTrigger>
                          <SelectValue placeholder="Requested change" />
                        </SelectTrigger>
                      </FormControl>
                      <SelectContent>
                        {sizes.map((size) => (
                          <SelectItem key={size} value={size}>
                            {size}
                          </SelectItem>
                        ))}
                      </SelectContent>
                    </Select>
                    <FormMessage />
                  </FormItem>
                )}
              />
            ))}
          </div>
          <AlertDialogFooter>
            <AlertDialogCancel>Cancel</AlertDialogCancel>
            <AlertDialogAction backgroundColor={primaryColor} type="submit">
              Save
            </AlertDialogAction>
          </AlertDialogFooter>
        </form>
      </Form>
    </>
  );
}

function isSizingProperty(propertyObject: {
  name: string;
  value: string | null;
}): propertyObject is {
  name: SizingPropertyName;
  value: string;
} {
  return (
    (propertyObject.name === "leggings" ||
      propertyObject.name === "tops" ||
      propertyObject.name === "sports-bra" ||
      propertyObject.name === "dress" ||
      propertyObject.name === "sports-jacket") &&
    propertyObject.value !== null
  );
}

function readableSizingPropertyName(name: SizingPropertyName) {
  switch (name) {
    case "leggings":
      return "Leggings";
    case "tops":
      return "Tops";
    case "sports-bra":
      return "Sports Bra";
    case "dress":
      return "Dress";
    case "sports-jacket":
      return "Sports Jacket";
    default:
      throw new Error("Invalid sizing property name");
  }
}

// TODO: This should move to common
function Row({
  label,
  value,
  highlight,
}: {
  label: string;
  value: string;
  highlight?: boolean;
}) {
  return (
    <div className="flex flex-row py-1.5">
      <div className="text-sm text-black">{label}</div>
      <div
        className={clsxm("ml-auto text-sm text-black", {
          "text-green-700": highlight,
        })}
      >
        {value}
      </div>
    </div>
  );
}

function RowDivider() {
  return <div className="bg-gray h-[1px] w-full"></div>;
}
