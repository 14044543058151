import { createFileRoute, notFound } from "@tanstack/react-router";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@customerPortal/components/ui/form";
import { Input } from "@customerPortal/components/ui/input";
import { Textarea } from "@customerPortal/components/ui/textarea";
import { createTRPCProxyClient } from "@trpc/client";
import type { AppRouter } from "@be/exports";
import { useState } from "react";
import { toast } from "sonner";
import { Button } from "@customerPortal/components/Button";

export const Route = createFileRoute(
  "/business/$businessSlug/_card/_orders/orders/complaint",
)({
  component: ComplaintForm,
  validateSearch: z.object({
    trackingId: z.string().optional(),
    confirmation: z.boolean().optional(),
  }),
  loader: async ({ params: { businessSlug }, context: { trpc } }) => {
    const complaintsEnabled =
      await trpc.customerPortal.orders.complaintsEnabled.query({
        businessSlug,
      });

    if (!complaintsEnabled) {
      throw notFound();
    }
  },
  notFoundComponent: () => (
    <p className="text-center">Complaints are disabled</p>
  ),
});

const formSchema = z.object({
  trackingId: z.string(),
  email: z.string().email(),
  details: z.string(),
  image: z
    .any()
    .refine((value) => value instanceof File, {
      message: "Please upload an image",
    })
    .transform((value) => value as File),
});

function ComplaintForm() {
  const { businessSlug } = Route.useParams();
  const { trackingId, confirmation } = Route.useSearch();
  const {
    customerPortalConfig: { primaryColor, backupEmail, shopDomain },
  } = Route.useRouteContext();
  const navigate = Route.useNavigate();

  const {
    trpc,
    orderConfig: { trackingIdPlaceholder },
  } = Route.useRouteContext();

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      trackingId,
    },
  });

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [displayConfirmation, setDisplayConfirmation] = useState(
    confirmation ?? false,
  );

  function onSubmit(values: z.infer<typeof formSchema>) {
    setIsSubmitting(true);
    submitForm({ values, businessSlug, trpc })
      .then(() => {
        setDisplayConfirmation(true);
        void navigate({
          from: "/business/$businessSlug/orders/complaint",
          to: ".",
          search: { confirmation: true, trackingId: values.trackingId },
        });
      })
      .catch((err) => {
        console.error("Failed to submit complaint", err);
        toast(
          `Failed to submit complaint. Please try again or contact ${backupEmail}.`,
        );
      })
      .finally(() => {
        setIsSubmitting(false);
      });
  }

  if (displayConfirmation) {
    return (
      <div>
        <h2 className="text-center text-lg font-medium">Complaint submitted</h2>
        <p className="mt-1 text-center text-sm font-light">
          We are sorry for the bad experience you had with your order. We have
          received your complaint correctly and we will get back to you as soon
          as possible.
        </p>
        <a href={`https://${shopDomain}`} target="_blank" rel="noreferrer">
          <Button className="mt-4 w-full" backgroundColor={primaryColor}>
            Back to {shopDomain}
          </Button>
        </a>
      </div>
    );
  }

  return (
    <>
      <div className="flex w-full pb-2">
        <span className="mx-auto text-base font-medium text-gray-500">
          Make a quality complaint
        </span>
      </div>
      <Form {...form}>
        <form
          onSubmit={(e) => void form.handleSubmit(onSubmit)(e)}
          className="space-y-4"
        >
          <FormField
            control={form.control}
            name="trackingId"
            disabled={isSubmitting}
            render={({ field }) => (
              <FormItem>
                <FormLabel className="text-gray-500">Order ID</FormLabel>
                <FormControl>
                  <Input
                    placeholder={trackingIdPlaceholder ?? "FRXXXXX"}
                    className="text-base text-black"
                    {...field}
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="email"
            disabled={isSubmitting}
            render={({ field }) => (
              <FormItem>
                <FormLabel className="text-gray-500">Email</FormLabel>
                <FormControl>
                  <Input
                    type="email"
                    placeholder="name@example.com"
                    className="text-base text-black"
                    {...field}
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="details"
            disabled={isSubmitting}
            render={({ field }) => (
              <FormItem>
                <FormLabel className="text-gray-500">Explanation</FormLabel>
                <FormControl>
                  {/* TODO: Make textarea resize automatically */}
                  <Textarea
                    placeholder="Please describe your issue"
                    className="text-base text-black"
                    {...field}
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="image"
            disabled={isSubmitting}
            render={({ field: { onChange, ...fieldProps } }) => (
              <FormItem>
                <FormLabel className="text-gray-500">Upload image</FormLabel>
                <FormControl>
                  {/* https://github.com/shadcn-ui/ui/discussions/2137 */}
                  <Input
                    {...fieldProps}
                    value={undefined}
                    type="file"
                    placeholder="Picture"
                    className="text-base text-black"
                    accept="image/*"
                    onChange={(e) => onChange(e.target.files?.[0])}
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <Button
            className="!mt-8 w-full"
            type="submit"
            backgroundColor={primaryColor}
            disabled={isSubmitting}
          >
            Submit
          </Button>
        </form>
      </Form>
    </>
  );
}

async function submitForm({
  values: { image, ...values },
  businessSlug,
  trpc,
}: {
  values: z.infer<typeof formSchema>;
  businessSlug: string;
  trpc: ReturnType<typeof createTRPCProxyClient<AppRouter>>;
}) {
  const { id: imageFileId, presignedUrl } =
    await trpc.customerPortal.orders.getComplaintImageUploadUrl.mutate({
      businessSlug,
      filename: image.name,
      contentType: image.type ? image.type : "application/octet-stream",
    });

  const r = await fetch(presignedUrl, {
    method: "PUT",
    body: image,
    headers: {
      "Content-Type": image.type,
      "x-ms-blob-type": "BlockBlob",
    },
  });

  if (!r.ok) {
    throw new Error("Failed to upload image");
  }

  await trpc.customerPortal.orders.submitComplaint.mutate({
    businessSlug,
    imageFileId,
    ...values,
  });
}
