import { createFileRoute, notFound } from "@tanstack/react-router";

export const Route = createFileRoute("/business/$businessSlug/_card/_orders")({
  beforeLoad: async ({ params: { businessSlug }, context: { trpc } }) => {
    const orderConfig = await trpc.customerPortal.orders.getConfig.query({
      businessSlug,
    });

    if (!orderConfig) {
      throw notFound();
    }

    return { orderConfig };
  },
});
