import {
  AlertDialogAction as OriginalAlertDialogAction,
  AlertDialogCancel as OriginalAlertDialogCancel,
} from "@customerPortal/components/ui/alert-dialog";
import chroma from "chroma-js";
import { ComponentProps, useState } from "react";

interface AlertDialogActionProps
  extends ComponentProps<typeof OriginalAlertDialogAction> {
  backgroundColor?: string;
}

export function AlertDialogAction({
  backgroundColor,
  ...props
}: AlertDialogActionProps) {
  const [isHovered, setIsHovered] = useState(false);

  const style = {
    backgroundColor: backgroundColor
      ? isHovered
        ? chroma(backgroundColor).alpha(0.8).hex()
        : backgroundColor
      : undefined,
    ...props.style,
  };

  return (
    <OriginalAlertDialogAction
      {...props}
      style={style}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      {props.children}
    </OriginalAlertDialogAction>
  );
}

interface AlertDialogCancelProps
  extends ComponentProps<typeof OriginalAlertDialogCancel> {
  backgroundColor?: string;
}

export function AlertDialogCancel({
  backgroundColor,
  ...props
}: AlertDialogCancelProps) {
  const [isHovered, setIsHovered] = useState(false);

  const style = {
    backgroundColor: backgroundColor
      ? isHovered
        ? chroma(backgroundColor).alpha(0.8).hex()
        : backgroundColor
      : undefined,
    ...props.style,
  };

  return (
    <OriginalAlertDialogCancel
      {...props}
      style={style}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      {props.children}
    </OriginalAlertDialogCancel>
  );
}
