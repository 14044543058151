import { createFileRoute } from "@tanstack/react-router";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@customerPortal/components/ui/form";
import { Input } from "@customerPortal/components/ui/input";
import { z } from "zod";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { Button } from "@customerPortal/components/Button";

export const Route = createFileRoute(
  "/business/$businessSlug/_card/_orders/orders/find",
)({
  component: OrderInput,
  validateSearch: z.object({
    email: z.string().optional(),
    trackingId: z.string().optional(),
  }),
});

function OrderInput() {
  const navigate = Route.useNavigate();

  const onSubmit = (values: { email?: string; trackingId?: string }) => {
    void navigate({
      from: "/business/$businessSlug/orders",
      to: "/business/$businessSlug/orders/manage",
      search: values,
    });
  };

  const {
    orderConfig: { verificationSystem },
  } = Route.useRouteContext();

  return (
    <>
      {verificationSystem === "email-and-tracking-id" ? (
        <EmailAndTrackingIdForm onSubmit={onSubmit} />
      ) : verificationSystem === "tracking-id" ? (
        <TrackingIdForm onSubmit={onSubmit} />
      ) : (
        <EmailForm onSubmit={onSubmit} />
      )}
    </>
  );
}

const trackingIdFormSchema = z.object({
  trackingId: z.string(),
});

function TrackingIdForm({
  onSubmit,
}: {
  onSubmit: (values: { trackingId?: string }) => void;
}) {
  const { trackingId } = Route.useSearch();

  const {
    customerPortalConfig: { primaryColor, findOrderHeading },
  } = Route.useRouteContext();

  const form = useForm<z.infer<typeof trackingIdFormSchema>>({
    resolver: zodResolver(trackingIdFormSchema),
    defaultValues: {
      trackingId: trackingId ?? "",
    },
  });

  return (
    <>
      <div className="flex w-full flex-col pb-2">
        <span className="mx-auto text-base font-medium text-gray-500">
          {findOrderHeading?.heading ?? "Access your order"}
        </span>
        {findOrderHeading?.subHeading && (
          <span className="mx-auto mb-1 mt-1 text-center text-xs font-light text-gray-500">
            {findOrderHeading.subHeading}
          </span>
        )}
      </div>
      <Form {...form}>
        <form
          onSubmit={(e) => void form.handleSubmit(onSubmit)(e)}
          className="mx-auto flex w-full max-w-[350px] flex-col items-center justify-center"
        >
          <FormField
            control={form.control}
            name="trackingId"
            render={({ field }) => (
              <div className="w-full space-y-0">
                <FormItem className="w-full">
                  <FormLabel className="text-gray-500">Tracking ID</FormLabel>
                  <FormControl>
                    <Input
                      required
                      autoFocus
                      placeholder="1234567890"
                      className="text-base text-black"
                      {...field}
                    />
                  </FormControl>
                  <FormMessage className="max-w-[90%] truncate" />
                </FormItem>
              </div>
            )}
          />

          <Button
            type="submit"
            className="mt-8 w-full"
            backgroundColor={primaryColor}
          >
            Find order
          </Button>

          <p className="mt-2 text-xs text-gray-400">
            Access your tracking link and invoice
          </p>
        </form>
      </Form>
    </>
  );
}

const emailFormSchema = z.object({
  email: z.string().email(),
});

function EmailForm({
  onSubmit,
}: {
  onSubmit: (values: { email: string }) => void;
}) {
  const { email } = Route.useSearch();

  const {
    customerPortalConfig: { primaryColor, findOrderHeading },
  } = Route.useRouteContext();

  const form = useForm<z.infer<typeof emailFormSchema>>({
    resolver: zodResolver(emailFormSchema),
    defaultValues: {
      email: email ?? "",
    },
  });

  return (
    <>
      <div className="flex w-full flex-col pb-2">
        <span className="mx-auto text-base font-medium text-gray-500">
          {findOrderHeading?.heading ?? "Access your order"}
        </span>
        {findOrderHeading?.subHeading && (
          <span className="mx-auto mb-1 mt-1 text-center text-xs font-light text-gray-500">
            {findOrderHeading.subHeading}
          </span>
        )}
      </div>
      <Form {...form}>
        <form
          onSubmit={(e) => void form.handleSubmit(onSubmit)(e)}
          className="mx-auto flex w-full max-w-[350px] flex-col items-center justify-center"
        >
          <FormField
            control={form.control}
            name="email"
            render={({ field }) => (
              <div className="w-full space-y-0">
                <FormItem className="w-full">
                  <FormLabel className="text-gray-500">Email</FormLabel>
                  <FormControl>
                    <Input
                      required
                      autoFocus
                      placeholder="name@example.com"
                      type="email"
                      className="text-base text-black"
                      {...field}
                    />
                  </FormControl>
                  <FormMessage className="max-w-[90%] truncate" />
                </FormItem>
              </div>
            )}
          />

          <Button
            type="submit"
            className="mt-8 w-full"
            backgroundColor={primaryColor}
          >
            Find order
          </Button>

          <p className="mt-2 text-xs text-gray-400">
            Access your tracking link and invoice
          </p>
        </form>
      </Form>
    </>
  );
}

const emailAndTrackingIdFormSchema = z.object({
  email: z.string().email(),
  trackingId: z.string(),
});

function EmailAndTrackingIdForm({
  onSubmit,
}: {
  onSubmit: (values: { email: string; trackingId: string }) => void;
}) {
  const { email, trackingId } = Route.useSearch();

  const {
    orderConfig: { trackingIdPlaceholder },
    customerPortalConfig: { findOrderHeading, primaryColor },
  } = Route.useRouteContext();

  const form = useForm<z.infer<typeof emailAndTrackingIdFormSchema>>({
    resolver: zodResolver(emailAndTrackingIdFormSchema),
    defaultValues: {
      trackingId: trackingId ?? "",
      email: email ?? "",
    },
  });

  return (
    <>
      <div className="flex w-full flex-col pb-2">
        <span className="mx-auto text-base font-medium text-gray-500">
          {findOrderHeading?.heading ?? "Access your order"}
        </span>
        {findOrderHeading?.subHeading && (
          <span className="mx-auto mb-1 mt-1 text-center text-xs font-light text-gray-500">
            {findOrderHeading.subHeading}
          </span>
        )}
      </div>
      <Form {...form}>
        <form
          onSubmit={(e) => void form.handleSubmit(onSubmit)(e)}
          className="mx-auto flex w-full max-w-[350px] flex-col items-center justify-center"
        >
          <FormField
            control={form.control}
            name="email"
            render={({ field }) => (
              <div className="w-full space-y-0">
                <FormItem className="w-full">
                  <FormLabel className="text-gray-500">Email</FormLabel>
                  <FormControl>
                    <Input
                      required
                      autoFocus
                      placeholder="name@example.com"
                      type="email"
                      className="text-base text-black"
                      {...field}
                    />
                  </FormControl>
                  <FormMessage className="max-w-[90%] truncate" />
                </FormItem>
              </div>
            )}
          />

          <FormField
            control={form.control}
            name="trackingId"
            render={({ field }) => (
              <div className="mt-4 w-full space-y-0">
                <FormItem className="w-full">
                  <FormLabel className="text-gray-500">Order ID</FormLabel>
                  <FormControl>
                    <Input
                      required
                      placeholder={trackingIdPlaceholder ?? "FRXXXXX"}
                      type="text"
                      className="text-base text-black"
                      {...field}
                    />
                  </FormControl>
                  <FormMessage className="max-w-[90%] truncate" />
                </FormItem>
              </div>
            )}
          />

          <Button
            type="submit"
            className="mt-8 w-full"
            backgroundColor={primaryColor}
          >
            Continue
          </Button>

          <p className="mt-2 text-xs text-gray-400">
            Tracking links, invoices, and modifications
          </p>
        </form>
      </Form>
    </>
  );
}
