import { createFileRoute } from "@tanstack/react-router";
import { useEffect } from "react";
import plantDoctorBackground from "@customerPortal/assets/plant-doctor-bg.jpg";
import beGreenLogo from "@customerPortal/assets/begreen-logo.webp";

export const Route = createFileRoute("/plant-doctor")({
  component: PlantDoctorPage,
});

function PlantDoctorPage() {
  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://www.octocom.ai/chatbot/bundle.js";
    script.id = "octocom-script";
    script.setAttribute(
      "data-config-id",
      "92e84924-4634-4593-a751-85e30a0b681e",
    );
    script.setAttribute("referrerpolicy", "no-referrer-when-downgrade");
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    <div className="flex h-screen flex-col bg-white">
      <div className="flex h-[54px] items-center bg-white p-3 px-20 opacity-0 sm:opacity-100">
        <a href="https://be.green/">
          <img src={beGreenLogo} className="mr-10 h-[20px]" />
        </a>
        <div className="space-x-10">
          <a
            href="https://be.green/"
            className="text-[17px] font-semibold text-[#333]"
          >
            Store
          </a>
          <a
            href="https://be.green/es/c/cuidados"
            className="text-[17px] font-semibold text-[#333]"
          >
            Plant Care
          </a>
          <a
            href="https://be.green/es/pagina/contacto"
            className="text-[17px] font-semibold text-[#333]"
          >
            Contact Us
          </a>
        </div>
      </div>
      <div className="relative flex grow flex-col">
        <div className="relative flex h-[60%]">
          <div className="absolute h-full w-full bg-black opacity-50"></div>
          <div
            className="h-auto w-full bg-['plantDoctorBackground'] bg-red-200"
            style={{
              backgroundImage: `url(${plantDoctorBackground})`,
              backgroundSize: "cover",
              backgroundPosition: "center",
            }}
          ></div>
        </div>

        <div
          id="octocom-container"
          className="sm:absolute sm:left-1/2 sm:top-16 sm:z-10 sm:h-[80vh] sm:max-h-[900px] sm:w-[400px] sm:-translate-x-1/2 2xl:w-[500px]"
        ></div>
      </div>
    </div>
  );
}
